import React from 'react';

export default function PrivacyPolicy() {
  return (
    <section className="section-privacy-policy">
      <div className="container-privacy margin-mobile">
        <div className="row no-gutters">
          <div className="col-12">
            <h1>Privacy &amp; cookie policy </h1>
            <p>Thank you for visiting our website <a href="https://flexyfoto.com" target="_blank" rel="noreferrer">www.flexyfoto.com</a>. By using our website, you may leave personal data behind. In this privacy and cookie policy we describe what we do with this data and the extent of your control over the data.</p>

            <h3>What personal data do we collect and when?</h3>
            <p>When you indicate that you would like to receive notifications from us, we ask you to share certain personal data, including your name and email address.</p>
            <p>Personal data can also be collected when you use <a href="https://flexyfoto.com" target="_blank" rel="noreferrer">www.flexyfoto.com</a> and this data can be passed on to us by the technology platforms that provide you with the service. It is, for instance, possible that your internet browser or mobile devices share certain data with us because these devices interact with <a href="https://flexyfoto.com" target="_blank" rel="noreferrer">www.flexyfoto.com</a>. You can find more information about this below in the section about cookies. This data comprises:</p>

            <ul>
              <li>Device IDs, location information, browser, network provider, operating system, screen resolution and the support of web technologies in your browser such as flash and java;</li>
              <li>Cookies, IP addresses, data that identifies your internet browsers and their version, and web beacons and tags;</li>
              <li>If you visit <a href="https://flexyfoto.com" target="_blank" rel="noreferrer">www.flexyfoto.com</a> on your smartphone, we also receive data about the brand and model of your smartphone, input method (for example touchscreen) and active operating system.</li>
            </ul>

            <h3>Your own control</h3>
            <p>Often internet browsers or mobile devices provide additional resources that allow you to determine yourself when your device collects or shares certain types of information. Your mobile device or internet browser can, for instance, provide resources you can use to check the use of cookies or the sharing of your location. We encourage you to use these resources. Where necessary, we ask you for permission.</p>

            <h3>Why and how we use (process) your personal data</h3>
            <p>We use the personal data we collect from you for the following purposes:</p>

            <ul>
              <li>For general research and analytical purposes: we process data about how our visitors use our website in order to better understand the behaviour and preferences of our customers. For instance, we collect geographical data because we want to know where in the world there is an interest in Flexyfoto. </li>
              <li>Technical information is useful for us because we continually want to improve our website, for example by ensuring that the website works on every possible device;</li>
            </ul>

            <h3>Protection and management of your personal data</h3>

            <h4>Storage of your data</h4>
            <p>We do not retain your personal data any longer than necessary to fulfil the purposes for which we collect this data, unless the law requires otherwise.</p>

            <h4>Your rights concerning your personal data</h4>
            <p>You have the right to access your data, to data portability and to restriction, amendment or deletion of the data if the data held by us is incorrect or unlawful. You also have the right to make an objection to the processing of your data; in particular you have the right to make an objection to the processing of your data for the purpose of marketing aimed directly at you. In situations where we have received your specific permission to process your personal data, you can withdraw your permission. You can reach us about the above-mentioned situations by sending an e-mail to hello@flexyfoto.com</p>

            <h3>Cookies</h3>
            <p>When you use our website, Flexyfoto receives information from your browser and stores this information. We use different methods, such as cookies, to collect this information, and these can contain the following data: (i) IP address; (ii) unique cookie code, cookie information and information about whether your device is equipped with software that provides access to certain functions; (iii) unique ID, type of device and screen resolution; (iv) domain, browser type and language, (v) operating system and system settings; (vi) country and time zone; (vii) information about your interaction with our site, such as click behaviour, (viii) access times and referring URLs.</p>
            <p>We use cookies to follow how our customers use <a href="https://flexyfoto.com" target="_blank" rel="noreferrer">www.flexyfoto.com</a> and to obtain insight into the preferences (such as country and language choice) of our customers. Our aim here is to provide our customers with a better service and to improve their online experience. We do not store the data we obtain via cookies for longer than necessary to fulfil the purposes for which we collect the data, unless the law requires otherwise.</p>
            <p>In general, we use two categories of cookies on our website:</p>

            <ul>
              <li>Functional: These cookies are necessary for the basic functionality of <a href="https://flexyfoto.com" target="_blank" rel="noreferrer">www.flexyfoto.com</a> and are therefore always enabled.</li>
              <li>Performance: With the help of these cookies we can improve the functionality of our website by monitoring how it is used. In some cases, these cookies allow us to process your request more quickly and make it possible for us to remember the preferences you have chosen. We make use of Google Analytics. You can find a link to the Google Analytics privacy/cookie policy <a href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage?hl=en" target="_blank" rel="noreferrer">here</a>. You can choose not to be followed by Google Analytics. Read more about this option <a href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage?hl=en" target="_blank" rel="noreferrer">here</a>.</li>
            </ul>

            <h3>Changes to our Privacy &amp; Cookie policy</h3>
            <p>If we revise our privacy &amp; cookie policy, we will post the changes on our website. If there is a legal requirement to ask for your permission or to inform you before we implement the changes, we will do so. This privacy &amp; cookie policy was last changed on 22 September 2020.</p>
          </div>
        </div>
      </div>
    </section>
  );
}


