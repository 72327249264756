import React from "react";
import Layout from "../components/common/Layout";
import NavBarSecondary from "../components/NavBarSecondary";
import PrivacyPolicy from "../components/sections/PrivacyPolicy";
import Footer from "../components/sections/Footer";

const Privacy = () => (
  <Layout>
    <NavBarSecondary />
    <main id="main">
      <PrivacyPolicy />
    </main>
    <Footer />
  </Layout>
);

export default Privacy;
