import React from "react";
import { Link } from "gatsby";
import NavBarLogo from "../_molecules/NavBarLogo";
import "./style.scss";

export default function NavBarSecondary() {
  return (
    <nav
      id="NavBarSecondary"
      className="row no-gutters sticky-top margin-mobile"
    >
      <div className="col-12 col-lg-6 d-flex align-items-center justify-content-center justify-content-lg-start">
        <Link to="/">
          <NavBarLogo />
        </Link>
      </div>
    </nav>
  );
}
